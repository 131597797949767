import React, { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuestsContentful } from 'modules/loading-screen/contentful/quests/selectors';
import { AppDispatch } from 'redux/typings';
import { getCohorts, getLocations } from '../../actions';
import {
  CohortQuestTitle,
  CohortScheduledOn,
  CohortSeparator,
  ContentMainSection,
  ManageCohortAddButton,
  ManageCohortHeader,
  ManageCohortTitle,
  ManageQuestCohortContainer,
} from '../../components';
import { selectCohorts } from '../../selectors';
import localizedStrings from 'localization';
import AddIcon from 'components/icons/AddIcon';
import { useCallback } from 'react';
import { useState } from 'react';
import AddQuestModal from './AddQuestModal';
import {
  selectIsQuestCompleted,
  selectActiveQuestCohortId,
  selectQuestsHash,
  selectLoadingAdditionalFinished,
  selectCohortOrganizationId,
} from 'modules/loading-screen/quests/selectors';
import { Cohort } from 'modules/settings/typings';
import CheckIcon from 'components/icons/CheckIcon';
import ChangeQuestModal from './ChangeQuestModal';
import {
  ProfileSection,
  ProfileSectionPanel,
  ProfileSectionTitle,
} from 'modules/profile/components';
import LoadingSpinner from 'components/loading-spinner';
import theme from 'utils/theme';
import { CenterLoader } from 'components/loading-spinner/components';
import useActionStatus from 'hooks/useActionStatus';
import { getOldQuests } from 'modules/loading-screen/quests/actions';
import { selectUserOrganizationId } from 'modules/auth/selectors';

const ManageQuests = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [cohortDetected, setCohortDetected] = useState('');
  const cohorts = useSelector(selectCohorts);
  const questsContentful = useSelector(selectQuestsContentful);
  const activeQuestCohortId = useSelector(selectActiveQuestCohortId);
  const questsHash = useSelector(selectQuestsHash);
  const questsUser = Object.keys(questsHash).map(id => questsHash[id]);
  const isQuestCompleted = useSelector(selectIsQuestCompleted);
  const loadingFinished = useSelector(selectLoadingAdditionalFinished);
  const organizationId = useSelector(selectCohortOrganizationId);
  const userOrganizationId = useSelector(selectUserOrganizationId);
  const userOrgId = userOrganizationId || organizationId;

  const [isPendingCohorts, wasFulfilledCohorts] = useActionStatus(getCohorts);

  const isSelectedQuest = (cohortId: string) => !isQuestCompleted && activeQuestCohortId === cohortId;

  const shouldShowLoader = useMemo(() =>
    !loadingFinished || isPendingCohorts || !wasFulfilledCohorts
    , [loadingFinished, isPendingCohorts, wasFulfilledCohorts]);

  const sortedCohorts = useMemo(() => {
    return [...cohorts]
      .sort((cohort1, cohort2) =>
        cohort1.id === activeQuestCohortId
          ? -1
          : cohort2.id === activeQuestCohortId
            ? 1
            : 0,
      )
      .reverse()
      .map((el: Cohort, index: number) => {
        const questUserData = questsUser.find(elq => elq.cohortId === el.id);
        return ({
          ...el,
          closedAt:
            Object.entries(questsHash).find(
              (q) => cohorts[index + 1]?.id === q[1].cohortId,
            ) || null,
          createdAt: questUserData?.createdAt || el.createdAt,
          completedAt: questUserData?.completedAt,
          lastVisited: questUserData?.updatedAt
        })
      })
      .reverse();
  }, [activeQuestCohortId, cohorts, questsHash, questsUser]);

  const openAddQuestModal = useCallback(() => {
    setShowAddModal(true);
  }, []);

  const openChangeQuestModal = useCallback((cohort) => {
    setCohortDetected(cohort);
    setShowChangeModal(true);
  }, []);

  const closeAddQuestModal = useCallback(() => {
    setShowAddModal(false);
  }, []);

  const closeChangeQuestModal = useCallback(() => {
    setShowChangeModal(false);
  }, []);

  useEffect(() => {
    dispatch(getOldQuests());
    dispatch(getCohorts());
  }, [dispatch]);

  useEffect(() => {
    userOrgId && dispatch(getLocations(userOrgId));
  }, [dispatch, userOrgId])

  return (
    <ProfileSection>
      <ProfileSectionTitle>{localizedStrings.quests}</ProfileSectionTitle>
      <ProfileSectionPanel>
        <>
          <AddQuestModal
            open={showAddModal}
            close={closeAddQuestModal}
            stateQuest={isQuestCompleted}
          />
          <ChangeQuestModal
            open={showChangeModal}
            close={closeChangeQuestModal}
            cohort={cohortDetected}
          />

          <ManageCohortHeader>
            <ManageCohortTitle>{localizedStrings.yourQuests}</ManageCohortTitle>
            <ManageCohortAddButton onClick={openAddQuestModal}>
              <AddIcon />
            </ManageCohortAddButton>
          </ManageCohortHeader>

          {
            shouldShowLoader ?
              <CenterLoader>
                <LoadingSpinner size={50} color={theme.colors.blue1[100]} />
              </CenterLoader>
              : sortedCohorts.map((questUserCohort) => {
                const createdAtQuestUser = questsUser.find(q => q.cohortId === questUserCohort.id)?.createdAt;
                const havePendingTasks = !questUserCohort.completedAt && questUserCohort.closedAt;

                return (
                  <React.Fragment key={questUserCohort.id}>
                    <ManageQuestCohortContainer>
                      <ContentMainSection>
                        <CohortQuestTitle
                          selected={isSelectedQuest(questUserCohort.id)}>
                          {questsContentful[questUserCohort.questContentfulId].title} {(!questUserCohort.completedAt && !isSelectedQuest(questUserCohort.id)) && localizedStrings.inProgress}
                        </CohortQuestTitle>

                        {createdAtQuestUser ? (
                          <CohortScheduledOn
                            selected={isSelectedQuest(questUserCohort.id)}>
                            {localizedStrings.cohortScheduledOn(new Date(createdAtQuestUser).toDateString())}
                          </CohortScheduledOn>
                        ) : null}

                        {(!questUserCohort.completedAt && questUserCohort.lastVisited) ? (
                          <CohortScheduledOn
                            selected={isSelectedQuest(questUserCohort.id)}>
                            {localizedStrings.cohortLastVisitOn(new Date(questUserCohort.lastVisited).toDateString(),)}
                          </CohortScheduledOn>
                        ) : null}

                        {havePendingTasks ? (
                          <CohortScheduledOn
                            selected={isSelectedQuest(questUserCohort.id)}>
                            {localizedStrings.youHavePendingTasks}
                          </CohortScheduledOn>
                        ) : null}

                        {/* COMPLETED */}
                        {questUserCohort.completedAt ? (
                          <CohortScheduledOn
                            selected={isSelectedQuest(questUserCohort.id)}>
                            {localizedStrings.cohortCompletedOn(new Date(questUserCohort.completedAt).toDateString())}
                          </CohortScheduledOn>
                        ) : null}

                      </ContentMainSection>

                      {questUserCohort.closedAt ? (
                        <ManageCohortAddButton
                          onClick={() => openChangeQuestModal(questUserCohort.enrollmentCode)}>
                          <CheckIcon />
                        </ManageCohortAddButton>
                      ) : null}
                    </ManageQuestCohortContainer>
                    <CohortSeparator />
                  </React.Fragment>
                )
              })}
        </>
      </ProfileSectionPanel>
    </ProfileSection>
  );
};

export default ManageQuests;
